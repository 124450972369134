<template>
  <div class="h5-wrap">
    <!-- <Menu ref="menu"></Menu>
    <start-video ref="startVideo" @videoLoaded="videoLoaded"></start-video>
    <open-scene
      ref="openScene"
      @animStartLoad="animStartLoad"
      @animLoaded="animLoaded"
    ></open-scene>
    <intro-one></intro-one>
    <intro-two></intro-two>
    <intro-three></intro-three>
    <card-list></card-list>
    <artist-list-h5 ref="artist"></artist-list-h5>
    <div class="index-foot">
      <div class="foot-bg">
        <img :src="require('../assets/h5/foot_bg_h5.png')" width="100%" />
      </div>
      <Foot></Foot>
    </div>
    <Loading v-if="loading" ref="loadingComp"></Loading> -->
  </div>
</template>
  <script>
// import Menu from "../components/Menu.vue";
// import IntroOne from "../components/Intro-01.vue";
// import IntroTwo from "../components/Intro-02.vue";
// import IntroThree from "../components/Intro-03.vue";
// import CardList from "../components/CardList.vue";
// import ArtistListH5 from "../components/ArtistListH5.vue";
// import StartVideo from "../components/StartVideo.vue";
// import OpenScene from "../components/OpenScene.vue";
// import Foot from "../components/Foot.vue";
// import Loading from "../components/Common/Loading.vue";
export default {
  components: {
    // Menu,
    // IntroOne,
    // IntroTwo,
    // IntroThree,
    // CardList,
    // ArtistListH5,
    // StartVideo,
    // OpenScene,
    // Foot,
    // Loading,
  },
  data() {
    return {
      wrap: null,
      scrollTop: 0,
      cancelEvent: true,
      isScrolling: false,
      videoReady: false,
      animReady: true,
      loading: true,
    };
  },
  methods: {
    sceneEnd() {
      this.cancelEvent = false;
    },
    cacuLoadingStatus() {
      const loading = !(this.videoReady && this.animReady);
      if (!loading) {
        this.$refs.startVideo.videPlay();
        this.$refs.loadingComp && this.$refs.loadingComp.loadEnd();
        setTimeout(() => {
          this.loading = false;
        }, 150);
      } else {
        this.loading = true;
      }
    },
    videoLoaded() {
      this.videoReady = true;
      this.cacuLoadingStatus();
    },
    animStartLoad() {
      this.animReady = false;
      this.cacuLoadingStatus();
    },
    animLoaded() {
      this.animReady = true;
      this.cacuLoadingStatus();
    },
    // handleWheel(e) {
    //   if (this.cancelEvent) {
    //     e.preventDefault();
    //   }
    //   // if (this.scrollTop === 0 && e.deltaY > 0) {
    //   //   // this.isTouch && this.scrollToTarget('scene-wrap')
    //   //   // console.log('11',this.$refs.openScene.$el.offsetTop)
    //   //   if (this.cancelEvent) {
    //   //     !this.isScrolling &&
    //   //       this.scrollToTarget(this.$refs.openScene.$el, 600);
    //   //   }
    //   // }
    //   // if (this.scrollTop === window.innerHeight && e.deltaY < 0) {
    //   //   // this.scrollToTarget('video-wrap')
    //   //   if (this.cancelEvent) {
    //   //     !this.isScrolling &&
    //   //       this.scrollToTarget(this.$refs.startVideo.$el, 600);
    //   //   }
    //   // }

    //   // if (this.scrollTop === window.innerHeight && e.deltaY > 30) {
    //   //   console.log("333");
    //   //   !this.isScrolling && this.$refs.openScene.animate();
    //   // }
    // },
    // handleScroll() {
    //   const scrollTop = window.pageYOffset;
    //   const target = document.querySelector('.out-wrap-h5').offsetTop;
    //   if (scrollTop >= target && scrollTop <= scrollTop + window.innerWidth * 810 / 375) {
    //     this.$refs.artist.autoScroll();
    //   }
    // },
  },
  mounted() {
    const target = this.$route.query.target;
    if (target) {
      this.$refs.menu.scrollToTarget(target);
    } else {
      history.scrollRestoration = "manual";
    }
    document.querySelector("body").style.height = "100vh";
    document.querySelector("body").style.overflow = "scroll";
    // window.addEventListener("scroll", this.handleScroll, true);
  },
  beforeCreate() {
    // const ischeckCode = window.localStorage.getItem("checkCode");
    // if (!ischeckCode) {
    //   return this.$router.push("/verify");
    // }
    if (!this.$isMobile) {
      return this.$router.push("/index");
    }
  },
  destroyed() {
    // window.removeEventListener("scroll", this.handleScroll, true);
  },
};
</script>
  <style lang="less">
@import "../lib/px2vw";
.h5-wrap {
  width: 100%;
  overflow: hidden;
  img {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    -khtml-user-select: none !important;
    user-select: none !important;
    pointer-events: none !important;
  }
}
.star-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.index-foot {
  padding-bottom: 156px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  padding-top: (90 / @base);
  .foot-bg {
    position: relative;
  }
}
</style>
  